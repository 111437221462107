.sponsors-page {
  background-color: black;
  color: white;
  text-align: center;
  padding: 20px;
}

.sponsor-section {
  margin-top: 40px;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.sponsor-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
  border-top: 2px solid red;
  border-bottom: 2px solid red;
  display: inline-block;
  padding: 5px 20px;
  color: #e5e4e2;
}

.sponsor-logo {
  margin-top: 10px;
}

.sponsor-logo img {
  max-width: 200px;
  height: auto;
  margin: 0 auto;
  display: block;
}

.sponsor-logo p {
  font-size: 18px;
  margin-top: 5px;
}
